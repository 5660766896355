import React from 'react'
import logoWhite from "../../images/logo-full-normal-white.svg";
import logo from "../../images/logo-full-white.svg";
import { Link, navigate } from 'gatsby';

export const Footer = () => {
    return (
        <section className="container px-[15px] mx-auto pt-[40px] pb-[32px]">
            <div className="bg-[#222222] rounded-2xl py-[20px] px-[30px] flex items-center justify-between">
                <img className="object-left lg:object-center object-cover w-[50px] h-[50px] lg:w-[340px] lg:h-[60px] hidden xl:block" src={logoWhite} alt="" />
                <img className="object-left lg:object-center object-cover w-[50px] h-[50px] lg:w-[340px] lg:h-[60px]  xl:hidden" src={logo} alt="" />
                <div className="flex flex-col items-end">
                    <div className="hidden lg:flex items-center mb-1">
                        <Link className="text-white ff-cg--medium mr-3" to="/">Home</Link>
                        <span className="h-[10px] w-[2px] bg-white"></span>
                        <Link className="text-white ff-cg--medium mx-3" to="/search">Catalog</Link>
                        <span className="h-[10px] w-[2px] bg-white"></span>
                        <Link className="text-white ff-cg--medium mx-3" to="/about">About</Link>
                        <span className="h-[10px] w-[2px] bg-white"></span>
                        <Link className="text-white ff-cg--medium mx-3" to="/business" state={{id: "bzn"}}>Business</Link>
                        <span className="h-[10px] w-[2px] bg-white"></span>
                        <Link className="text-white ff-cg--medium mx-3" to="/contact" state={{id: "develop"}}>Contact Us</Link>
                        <span className="h-[10px] w-[2px] bg-white"></span>
                        <Link className="text-white ff-cg--medium ml-3" to="/faq">FAQs</Link>
                    </div>
                    <p className="text-white ff-cg--semibold text-right text-[11px] ml-[20px] lg:text-[16px]  max-[350px]:w-44 w-60 lg:w-full">Copyright © 2023 University of Maryland Global Campus. All Rights Reserved.</p>
                </div>
            </div>
        </section>
    )
}
