import axios from 'axios';

export const getEnrollments = async () => {
    const token = typeof window !== 'undefined' && localStorage.getItem('access_token');
    let element: any;

    var config = {
        method: 'get',
        url: process.env.API_URL + '/api/enrollment',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    await axios(config)
        .then(function (response) {
            element = response.data.data;
        })
        .catch(function (error) {
            console.log('****** this error: ', error);
        });

    return element;

}

export const getSeats = async (courseUuid:string) => {
    const token = typeof window !== 'undefined' && localStorage.getItem('access_token');
    let element: any;

    var data = JSON.stringify({
        "courseUuid": courseUuid
    });

    var config = {
        method: 'post',
        url: process.env.API_URL + '/api/seats/assigned?limit=100&page=1',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        data: data
    };

    await axios(config)
        .then(function (response) {
            element = response.data.data;
        })
        .catch(function (error) {
            console.log('****** this error: ', error);
        });

    return element;

}

export const assignSeats = async (courseUuid:string,toUsersuid: any) => {
    const token = typeof window !== 'undefined' && localStorage.getItem('access_token');
    let element: any;

    var data = JSON.stringify({
        "toUsersUuid": toUsersuid,
        "courseUuid": courseUuid
    });

    var config = {
        method: 'post',
        url: process.env.API_URL + '/api/seats/assign',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        data: data
    };

    await axios(config)
        .then(function (response) {
            console.log(response.data);
            element = response.data;
        })
        .catch(function (error) {
            console.log('****** this error: ', error);
            element = error.response.data
        });

    return element;

}

export const getApplications = async () => {

    const token = typeof window !== 'undefined' && localStorage.getItem('access_token');
    let element: any;

    var config = {
        method: 'get',
        url: process.env.API_URL + '/api/applications?limit=100&page=1',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    await axios(config)
        .then(function (response) {
            element = response.data.data;
        })
        .catch(function (error) {
            console.log('****** this error: ', error);
        });

    return element;

}

export const getApplicationsDetailed = async () => {

    const token = typeof window !== 'undefined' && localStorage.getItem('access_token');
    let element: any;

    var config = {
        method: 'get',
        url: process.env.API_URL + '/api/applications?limit=100&page=1',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const response = await axios(config);

    element = response.data.data;
    
    return element;

}

export const getUsers = async () => {
    const token = typeof window !== 'undefined' && localStorage.getItem('access_token');
    let element: any;

    var config = {
        method: 'get',
        url: process.env.API_URL + '/api/admin-group/groups/members',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    await axios(config)
        .then(function (response) {
            element = response.data.data;
        })
        .catch(function (error) {
            console.log('****** this error: ', error);
        });

    return element;
}

export const getEnrollment = async () => {
    const token = typeof window !== 'undefined' && localStorage.getItem('access_token');
    let element: any;

    var config = {
        method: 'get',
        url: process.env.API_URL + '/api/enrollment/count',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    await axios(config)
        .then(function (response) {
            element = response.data.count;
        })
        .catch(function (error) {
            console.log('****** this error: ', error);
        });

    return element;
}

export const searchUsers = async (firstName: string,status: string) => {
    const token = typeof window !== 'undefined' && localStorage.getItem('access_token');
    let element: any;

    let namestring: string;
    
    
    firstName !== "" ? namestring = `&keyword=${firstName}` : namestring = '';

    var config = {
        method: 'get',
        url: process.env.API_URL + `/api/admin-group/groups/members/?status=${status}${namestring}`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    await axios(config)
        .then(function (response) {
            element = response.data.data;
        })
        .catch(function (error) {
            console.log('****** this error: ', error);
        });

    return element;
}