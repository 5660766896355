import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, navigate } from "gatsby";
import { Header } from "../components/Header/Header";

import {
  CircleStackIcon,
  ShoppingCartIcon,
  MagnifyingGlassCircleIcon,
  AdjustmentsVerticalIcon,
  PencilSquareIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { Footer } from "../components/Footer/Footer";
import { getEnrollments } from "../helpers/courses";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";
import LoaderIcon from "../images/loader.svg";

const Students = () => {
  const userName =
    typeof window !== "undefined" && localStorage.getItem("name");
  const [signed, setSigned] = useState(false);
  const [enrolls, setEnrolls] = useState([]);

  const [loading, setLoading] = useState(true);

  const loginElement: any = useSelector((state: RootState) => state.loginSlice);

  useEffect(() => {
    if (loginElement.items.status) {
      setSigned(true);
    } else {
      navigate("/");
    }
  }, [loginElement]);

  const getEnrollmentsUser = async () => {
    setLoading(true);
    const enrollments = await getEnrollments();
    setEnrolls(enrollments);
    setLoading(false);
  };

  useEffect(() => {
    getEnrollmentsUser();
  }, []);

  return (
    <Layout signed={signed}>
      <div className="bg-[#f2f2f2]">
        <div className="mb-[40px]">
          {/* header */}
          {/* <Header isSignIn={signed} /> */}

          <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[40px] lg:pb-0">
            <div className="lg:flex lg:items-center lg:justify-between pb-10">
              <div className="flex items-center">
                <img src="" alt="" />
                <div className="mb-4 md:mb-0">
                  <h2 className="text-[30px] lg:text-[60px] ff-cg--semibold leading-none">
                    Courses
                  </h2>
                </div>
              </div>
              <div className="md:flex items-center gap-4">
                <button className="mb-4 md:mb-0 w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                  <CircleStackIcon className="h-6 w-6" />
                  <span className="ff-cg--semibold ml-[20px]">Export CSV</span>
                </button>
                <Link
                  to="/search"
                  className="mb-4 md:mb-0 w-full lg:w-fit flex items-center justify-between border border-solid border-[#fdbf38] bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl"
                >
                  <ShoppingCartIcon className="h-6 w-6" />
                  <span className="ff-cg--semibold ml-[20px]">Buy Courses</span>
                </Link>
              </div>
            </div>
            <div className="lg:flex lg:items-center gap-8 pb-10">
              <div className="shadow-lg flex items-center px-[14px] py-[7px] rounded-2xl w-full cursor-pointer bg-white mb-4 md:mb-0">
                <MagnifyingGlassCircleIcon className="h-6 w-6 text-[#da1a32] mr-[15px]" />
                <input
                  className="w-full ff-cg--semibold placeholder:text-[#000000] p-[10px] focus:outline-none"
                  type="search"
                  placeholder="Search Courses"
                />
              </div>
              <button className="w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                <AdjustmentsVerticalIcon className="h-6 w-6" />
                <span className="ff-cg--semibold ml-[20px] whitespace-nowrap">
                  Filter By
                </span>
              </button>
            </div>
            <div className="grid gap-4 gap-5 lg:gap-10 lg:grid-cols-12">
              <div className="col-span-12">
                <div className="rounded-xl bg-white shadow-lg h-full p-[3px]">
                  <div className="overflow-x-auto">
                    {enrolls.length ? (
                      <table className="lg:w-full rounded-2xl">
                        <thead className="text-white rounded-2xl  border-[#da1a32]">
                          <tr>
                            <th className="bg-[#da1a32] rounded-tl-xl text-left ff-cg--semibold py-4 px-8">
                              Title
                            </th>
                            <th className="bg-[#da1a32] text-left ff-cg--semibold py-4 px-8">
                              Seats
                            </th>
                            <th className="bg-[#da1a32] rounded-tr-xl text-right ff-cg--semibold py-4 px-8"></th>
                          </tr>
                        </thead>
                        <tbody className="">
                          <>
                            {enrolls.map((item: any, index) => {
                              const percentage =
                                (parseInt(item.counter) /
                                  parseInt(item.quantity)) *
                                100;
                              return (
                                <tr
                                  className="border-b border-solid"
                                  key={index}
                                >
                                  <td className="py-4 px-8">
                                    {item.course.title}
                                  </td>
                                  <td className="py-4 px-8">
                                    <div>
                                      <p className="text-[18px] lg:text-[30px]">
                                        {item.counter}/{item.quantity}
                                      </p>
                                      <div className="w-full bg-[#dddddd] h-2 rounded-full">
                                        <div
                                          className="bg-[#da1a32] h-2 rounded-full max-w-full"
                                          style={{
                                            width: `${percentage.toString()}%`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="py-4 px-8">
                                    <div className="flex items-center justify-end gap-6">
                                      <button className="w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                                        <Link
                                          to="/assigned-students"
                                          className="flex"
                                          state={{ elementItem: item }}
                                        >
                                          <EyeIcon className="h-6 w-6" />
                                          <span className="ff-cg--semibold ml-[20px] whitespace-nowrap">
                                            View Seats
                                          </span>
                                        </Link>
                                      </button>
                                      {parseInt(item.quantity) ===
                                      parseInt(item.counter) ? (
                                        <button className="w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                                          <p className="flex">
                                            <PencilSquareIcon className="h-6 w-6" />
                                            <span className="ff-cg--semibold ml-[20px] whitespace-nowrap">
                                              Course is Full
                                            </span>
                                          </p>
                                        </button>
                                      ) : (
                                        <button className="w-full lg:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl">
                                          <Link
                                            to="/assigned"
                                            className="flex"
                                            state={{ elementItem: item }}
                                          >
                                            <PencilSquareIcon className="h-6 w-6" />
                                            <span className="ff-cg--semibold ml-[20px] whitespace-nowrap">
                                              Assign Seats
                                            </span>
                                          </Link>
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        </tbody>
                      </table>
                    ) : (
                      <div className="w-full h-full flex justify-center pt-20 pb-20">
                        <div className="text-center">
                          {loading ? (
                            <>
                              <img
                                src={LoaderIcon}
                                className={`w-[120px] h-[120px] mmb-4 inline-block`}
                              />
                              <p className="font-bold text-4xl mb-2 ff-cg--semibold">
                                Loading Results
                              </p>
                              <p className="text-xl">
                                Please wait a moment while we set things up for
                                you!
                              </p>
                            </>
                          ) : (
                            <p className="text-xl">
                              Theres no results for your search
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className="flex items-center justify-between py-4 px-8">
                    <p className="ff-cg--semibold">Showing 1 - 5 of 10 Items</p>
                    <div className="flex items-center gap-4">
                      <button className="rounded-md flex items-center justify-center text-[18px] bg-[#da1a32] text-white w-10 h-10">
                        <span className="ff-cg--semibold">1</span>
                      </button>
                      <button className="rounded-md flex items-center justify-center text-[18px] border-2 border-solid border-[#da1a32] text-[#da1a32] w-10 h-10">
                        <span className="ff-cg--semibold">2</span>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          {/* footer */}
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default Students;
export { Head } from "../components/Layout/Head";
